:root {
  --black: #000000;
  --white: #ffffff;
  --pink: #ce3b9d;
  --blue: #2b5af3;
  --green: #38ff8e;
  --darkBlue: #040b28;
  --gray: #24293b;
  --br-gray: #47495a;
  --gradient: linear-gradient(99deg, #38ff8e 0%, #2b5af3 100%);
  --border-radius-5: 5px;
  --border-radius-15: 15px;
  --body-text: "Inter";
  --head-text: "violetsans";
  --w3m-accent: #2b5af3;
  --cardColor: #fffffff;
  --body-base: #f8f8f8;
  --box-shadow: 0 0 27px rgba(35, 31, 32, 0.05);
  --table-border: #d7d7d7;
  --input-border: #b4b4b4;
}

* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

@font-face {
  font-family: "Inter";
  src: url(../fonts/inter/Inter-Regular.ttf);
}

@font-face {
  font-family: "violetsans";
  src: url(../fonts/violetsans/VioletSans-Regular.ttf);
}

h1.MuiTypography-root,
h2.MuiTypography-root,
h3.MuiTypography-root,
h4.MuiTypography-root,
h5.MuiTypography-root,
h6.MuiTypography-root {
  font-family: var(--head-text);
  text-transform: uppercase;
  font-weight: 600;
}

.grid-main .grid-item {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}

.text-decoration-none {
  text-decoration: none !important;
}

.flex-all {
  display: flex;
  justify-content: space-between;
}

.page-body {
  padding: 20px;
  min-height: 100vh;
  background: var(--body-base);
}

.pink-color {
  color: #fb2b76 !important;
}

.admin-login-main-flex .form-group .form-control input {
  width: 100%;
}

.form-group .form-control input {
  width: auto;
  padding: 11px 15px;
  border-radius: 22px;
  font-size: 16px;
  line-height: 15px;
  font-family: var(--body-text);
  /* color: #000; */
  border: 1px solid #3e3e3e;
}

.form-group .form-control input::placeholder {
  font-size: 14px;
  line-height: 15px;
  font-family: var(--body-text);
  opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-width: 0px;
}

fieldset {
  border: 0px;
}

.page-border {
  border-radius: 15px;
  padding: 20px;
  background-color: #1e1e1e;
}

.btn-main-primary .btn-primary,
.btn-main-primary .btn-primary:hover {
  border-radius: 22px;
  color: #fff;
  font-size: 14px;
  line-height: 21px;
  font-family: var(--body-text);
  padding: 8px 20px;
  text-transform: capitalize;
  background: transparent linear-gradient(90deg, #b21cd8 0%, #14f9ff 100%) 0% 0% no-repeat padding-box;
}

.btn-main-primary .btn-primary-sub,
.btn-main-primary .btn-primary-sub:hover {
  color: #ffffff;
  border-radius: 7px;
  /* background: transparent linear-gradient(88deg, #b21cd8, #14f9ff) 0 0 no-repeat padding-box; */
  background: var(--gradient);
}

.border-btn-main .border-btn,
.border-btn-main .border-btn:hover {
  border-radius: 17px;
  background-color: #ffffff1a;
  padding: 6px 14px 5px;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  font-family: var(--body-text);
  text-transform: capitalize;
}

.common-para {
  font-size: 14px !important;
  line-height: 21px !important;
  font-family: var(--body-text);
}

/* start admin login css */

.admin-login-main-flex {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  /* background-color: #161616; */
  background: url(../images/png/glassbg-full.png) no-repeat;
  position: relative;
  overflow: hidden;
}

.admin-login-left-main {
  width: 100%;
  max-width: 50%;
  flex: 0 0 50%;
}

.admin-login-right-main {
  width: 100%;
  position: relative;
  z-index: 1;
}

.login-left-bg {
  height: 100vh;
  object-fit: cover;
  background-repeat: no-repeat;
  /* background-image: url(../images/png/login-bg.png); */
  background-size: 100% 100%;
  background-size: cover;
  width: 100%;
  background-position: center;
}

.auth-logo-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.auth-logo {
  /* height: 100px;
  width: 100%; */
  object-fit: contain;
}

.admin-login-main {
  max-width: 380px;
  margin: auto;
  width: 100%;
  border-color: #ffffff;
  border-bottom-width: 2px;
  /* background-color: #fff9; */
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(1px);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.25);
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 30px;
  align-items: center;
  padding: 30px;
}

.login-btn-main .login-btn {
  margin-top: 28px;
  width: 100%;
}

.varify-btn-main.verify-btn {
  margin-top: 28px;
}

.checkbox-main {
  display: flex;
  align-items: center;
}

.checkbox-main .MuiButtonBase-root {
  margin-right: 10px;
  padding: 0px;
}

.checkbox-main .checkbox-lable {
  color: #9babc5;
  margin-left: 0px;
  margin-right: 0px;
  font-size: 15px;
  line-height: 20px;
  font-family: var(--body-text);
}

.mission-checkbox-flex {
  margin-top: 35px !important;
}

.mission-checkbox-flex .mission-checkbox-label {
  width: 100px !important;
}

.checkbox-lable .MuiCheckbox-root {
  padding: 0px;
  margin-right: 10px;
}

.checkbox-lable .MuiFormControlLabel-label {
  font-family: var(--body-text);
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.admin-login-inner .admin-wel-text {
  color: var(--black);
  font-size: 24px;
  line-height: 25px;
  font-family: var(--head-text);
  margin-bottom: 4px;
}

.admin-login-inner .admin-sign-para {
  margin-bottom: 32px;
  color: #7c7c7c;
}

.admin-login-inner .admin-sign-para.margin-top {
  margin-bottom: 32px;
  color: #ffffff;
  margin-top: 20px;
  cursor: pointer;
}

.input-box .form-lable {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  font-family: var(--body-text);
  margin-bottom: 6px;
  color: var(--black);
  margin-top: 0px;
}

.input-box {
  margin-bottom: 10px;
}

.cms-margin {
  margin-bottom: 10px !important;
}

.admin-forgot-para {
  color: #f7b90f;
}

.password-form-control .MuiInputBase-root .passwrd-eye {
  color: #fefeff;
}

.input-box .MuiInputBase-root {
  width: 100%;
  border-radius: 22px;
  color: var(--black);
}

.input-box .MuiInputBase-root fieldset {
  border-color: var(--black);
}

.input-box .MuiInputBase-root svg {
  color: var(--black);
}

.password-form-control .MuiInputBase-root {
  border: 1px solid #3e3e3e;
  /* background-color: #161616; */
}

.password-form-control .MuiInputBase-root input {
  border: 0px;
}

.input-box .MuiInputBase-root fieldset:hover {
  border-color: transparent;
}

.input-box .MuiInputBase-root .MuiInputAdornment-root {
  margin: 0px;
}

.checkbox-main .MuiSvgIcon-root {
  color: #4facfe;
  width: 20px;
  height: 20px;
}

.password-input-box fieldset {
  border: 1px solid #3e3e3e;
}

.password-input-box svg {
  color: #fff;
}

.password-input-box input {
  padding: 12px 14px;
  font-size: 14px;
  line-height: 15px;
  font-family: var(--body-text);
  color: #fff;
}

/* end admin login css */

/* start admin dashboard page structure css */

.dashboard-left-main {
  flex: 0 0 260px;
  max-width: 260px;
  width: 100%;
  transition: 0.5s all;
}

.dashboard-right-main {
  flex: 1;
  /* width: calc(100% - 280px); */
}

.dashboard-containt-main {
  /* background-color: #1a123a; */
  /* background: var(--cardColor);
  box-shadow: var(--box-shadow); */
  margin-left: 20px;
  height: calc(100vh - 135px);
  overflow: auto;
  margin-top: 95px;
  transition: 0.5s all;
  border-radius: 1.25rem;
}

.dashboard-content {
  /* position: fixed;
  width: 100%; */
  /* padding: 20px;
  background: var(--white); */
}

.dashboard-main {
  display: flex;
  width: 100%;
}

.box-frame {
  background: var(--white);
  box-shadow: var(--box-shadow);
  border-radius: 1.25rem;
  padding: 1.25rem;
}

/* end admin dashboard page structure css */

/* start admin sidebar css */
.admin-sidebar-icons {
  margin-right: 15px;
  height: 18px;
  width: 18px;
  object-fit: contain;
}

.admin-sidebar-main {
  height: calc(100vh - 135px);
  overflow: auto;
  width: 260px;
  z-index: 999;
  transition: 0.5s all;
  border-radius: 15px;
  position: fixed;
  position: fixed;
  top: 115px;
  background: var(--white);
  box-shadow: var(--box-shadow);
}

.admin-sidebar-list-main {
  padding: 20px;
}

.admin-header-drop-main .drop-header-btn {
  padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-list {
  padding: 0px;
  position: relative;
}

.profile-noti-text {
  background-color: #fb2b76;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  font-size: 10px;
  line-height: 16px;
  font-family: var(--body-text);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
}

.admin-sidebar-list-main .admin-sidebar-listitem {
  padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-link {
  font-size: 14px;
  line-height: 21px;
  font-family: var(--head-text);
  color: var(--gray);
  display: flex;
  padding: 7px 10px 7px;
  margin-bottom: 5px;
  width: 100%;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
}

.admin-sidebar-list-main .admin-sidebar-link.active {
  color: #ffffff;
  border-radius: 7px;
  /* background: transparent linear-gradient(88deg, #b21cd8, #14f9ff) 0 0 no-repeat padding-box; */
  background: var(--gradient);
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* / / Track / / */
.scrollbar::-webkit-scrollbar-track {
  background: #1e1e1e;
}

/* / / Handle / / */
.scrollbar::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 8px;
}

/* / / Handle on hover / / */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

/* end admin sidebar css */

/* start admin header css */

.admin-header-main {
  z-index: 9;
  padding: 10px 20px;
  transition: 0.5s all;
  border-radius: 15px;
  margin-bottom: 20px;
  position: fixed;
  top: 20px;
  left: 20px;
  right: 20px;
  background: var(--white);
  box-shadow: var(--box-shadow);
}

.admin-sidebar-logo {
  width: 100%;
  height: 48px;
  object-fit: contain;
  max-width: 200px;
}

.admin-header-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-header-left .header-right-wrape {
  display: flex;
}

.admin-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.admin-header-icon-box {
  margin: 0px 10px;
}

.admin-header-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.admin-header-profile-icon {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
}

/* end admin header css */

/* start admin dashboard page */

.dashboard-content .admin-page-title {
  font-size: 20px;
  line-height: 30px;
  font-family: var(--body-text);
  margin-bottom: 15px;
}

.dashboard-content .admin-page-para {
  font-size: 16px;
  line-height: 25px;
  font-family: var(--body-text);
  margin-bottom: 20px;
}

.admin-dashboard-inner-box {
  /* border: 1px solid #D1D5DB; */
  max-width: 100%;
  width: 100%;
  height: 90px;
  border-radius: 10px;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  background-color: var(--blue);
  min-height: 116px;
  align-items: center;
  margin-bottom: 1.25rem;
}

.active .admin-dashboard-inner-box {
  background: var(--green);
}

.admin-dashboard-inner-box .admin-dash-text {
  color: var(--white);
  font-size: 11px;
  line-height: 25px;
  font-family: var(--body-text);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.active .admin-dashboard-inner-box .admin-dash-text {
  color: var(--black);
}

.admin-dashboard-inner-box .admin-dash-price {
  color: var(--white);
  font-size: 36px;
  line-height: 46px;
  font-family: var(--head-text);
  font-weight: 500;
  letter-spacing: 1px;
}

.header-rows {
  border-bottom: 1px solid #999999;
  padding-bottom: 10px;
  align-items: center;
}

.header-text {
  font-family: var(--head-text);
  text-transform: uppercase;
}

.active .admin-dashboard-inner-box .admin-dash-price {
  color: var(--black);
}

.dash-icons {
  height: 45px;
  width: 100%;
  object-fit: cover;
}

.dashboard-graph {
  height: 336px;
  width: 100%;
  min-width: 100%;
  object-fit: cover;
}

.page-table-main .table-container {
  padding-bottom: 7px;
  background-color: #1e1e1e;
  box-shadow: none;
  overflow: auto;
  max-width: calc(100vw - 0px);
}

.page-table-main .table {
  min-width: 1100px;
}

.page-table-main .table-th {
  font-size: 14px;
  line-height: 21px;
  color: #9babc5;
  font-family: var(--body-text);
  padding: 10px 10px 10px 0px;
  border-bottom: 1px solid #3e3e3e;
  text-align: left;
}

.page-table-main .table .table-td:first-child {
  padding-left: 0px;
}

.page-table-main .table-td {
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  font-family: var(--body-text);
  padding: 10px 10px 10px 0px;
  border-bottom: 0px;
  text-align: left;
}

.page-table-main .table .table-th:nth-child(1),
.page-table-main .table .table-td:nth-child(1) {
  width: 15%;
}

.page-table-main .table .table-th:nth-child(2),
.page-table-main .table .table-td:nth-child(2) {
  width: 15%;
}

.page-table-main .table .table-th:nth-child(3),
.page-table-main .table .table-td:nth-child(3) {
  width: 15%;
}

.page-table-main .table .table-th:nth-child(4),
.page-table-main .table .table-td:nth-child(4) {
  width: 35%;
}

.admin-sub-title-main .admin-sub-title {
  color: #ffffff;
  font-size: 18px;
  line-height: 27px;
  font-family: var(--body-text);
  margin-bottom: 15px;
}

.view-btn-main .view-order-btn {
  height: 28px;
  min-width: 96px;
}

.view-btn-main .view-order-btn:hover {
  height: 28px;
  font-size: 12px;
  line-height: 18px;
  min-width: 100px;
  color: #ffffff;
}

.admin-dash-box {
  border-radius: 10px;
  background-color: #1e1e1e;
  padding: 15px 25px 15px 25px;
}

.admin-dashboad-row {
  margin-bottom: 20px;
}

.display-row>* {
  padding: 0px 8px;
}

.display-row {
  margin-left: -8px;
  margin-right: -8px;
}

/* end admin dashboard page */

.table-lable-main {
  font-family: var(--body-text);
  font-size: 12px;
  line-height: 18px;
}

.paid-lable-dots {
  background-color: #04ab47;
}

.paid-lable {
  color: #04ab47;
}

.table-lable-dots {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin-right: 4px;
}

.table-lable-flex {
  display: flex;
  align-items: center;
}

.failed-lable-dots {
  background-color: #e4004d;
}

.failed-lable {
  color: #e4004d;
}

.pending-lable-dots {
  background-color: #f39c12;
}

.pending-lable {
  color: #f39c12;
}

/* width */
.page-table-main .table-container::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

/* Track */
.page-table-main .table-container::-webkit-scrollbar-track {
  background: #161616;
  border-radius: 5px;
}

/* Handle */
.page-table-main .table-container::-webkit-scrollbar-thumb {
  background: #161616;
}

/* Handle on hover */
.page-table-main .table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* start add user page */

.add-user-containt .add-basic-text {
  color: #fff;
  margin: 0px 0px 4px;
}

.add-user-containt .add-section-text {
  color: #9babc5;
  margin-bottom: 20px;
}

.add-user-input .form-control-textarea {
  width: 100%;
  border-radius: 22px;
  min-height: 70px;
  resize: none;
  background-color: #161616;
  border: 0px;
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-family: var(--body-text);
  padding: 15px;
  border: 1px solid #3e3e3e;
}

.radio-main .radiogroup .MuiButtonBase-root.Mui-checked {
  color: #9babc5;
}

.radio-main .MuiFormControlLabel-label {
  color: #9babc5;
  font-size: 14px;
  line-height: 21px;
  font-family: var(--body-text);
}

.user-btn-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

/* end add user page */

/*  start dropdown css  */

.dropdown-box .form-control {
  width: 100%;
  position: relative;
}

.dropdown-box .form-control .dropdown-select {
  background-color: #161616;
}

.dropdown-box .notify-btn {
  background-color: #04ab47;
}

.dropdown-select .MuiSelect-select {
  padding: 9px 15px;
  font-size: 12px;
  line-height: 24px;
  color: #fff;
  font-family: var(--body-text);
  display: flex;
  border: 1px solid #3e3e3e;
  border-radius: 22px;
  white-space: inherit !important;
}

.dropdown-select .MuiSelect-select:focus {
  border-radius: 22px;
}

.form-control fieldset {
  border: none;
  border-radius: 22px;
}

.grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

/* / end dropdown css / */

/* start admin add user page */

.discard-btn-main .discard-user-btn,
.discard-btn-main .discard-user-btn:hover {
  min-width: 83px;
  margin-right: 10px;
  height: 36px;
  background-color: #ffffff1a;
}

.user-save-icon {
  margin-right: 8px;
  object-fit: contain;
}

.save-btn-main .save-user-btn,
.save-btn-main .save-user-btn:hover {
  min-width: 87px;
  background: transparent linear-gradient(90deg, #b21cd8 0%, #14f9ff 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  height: 36px;
}

.admin-tabs-main .admin-tab {
  font-size: 14px;
  line-height: 21px;
  font-family: var(--body-text);
  color: #9babc5;
  text-transform: capitalize;
  padding: 10px 15px;
  background-color: #1e1e1e;
  border-radius: 25px;
  margin-right: 20px;
}

.admin-tabs-main .admin-tab.Mui-selected {
  color: #fff;
  background: transparent linear-gradient(90deg, #b21cd8 0%, #14f9ff 100%) 0% 0% no-repeat padding-box;
  border-radius: 25px;
}

.admin-tabs-main .MuiTabs-indicator {
  background-color: transparent;
  height: 0px;
}

.admin-tabpanel .edit-highlight-text {
  color: #fff;
  font-family: var(--body-text);
  margin-bottom: 4px;
}

.admin-tabpanel .edit-para-text {
  margin-bottom: 24px;
  color: #6b7280;
}

.admin-tabpanel>.MuiBox-root {
  padding: 24px 0px;
}

/* end admin add user page */

/* start admin edit user page */

.edit-profile-flex {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

/* end admin edit user page */

.dropdown-box .notify-btn,
.dropdown-box .notify-btn:hover {
  min-width: 87px;
  background: transparent linear-gradient(90deg, #b21cd8 0%, #14f9ff 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  height: 36px;
  margin-top: 30px;
  border-radius: 10px;
}

/* delete button with checkbox---- */

.delete-box .delete-btn,
.delete-box .delete-btn:hover {
  min-width: 87px;
  background: transparent linear-gradient(90deg, #b21cd8 0%, #14f9ff 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  height: 36px;
  margin-right: 30px;
  border-radius: 10px;
}

/* start file upload img css */

.file-upload-btn-main .file-upload-btn,
.file-upload-btn-main .file-upload-btn:hover {
  min-width: 150px;
  padding: 0px;
  background-color: #161616;
  height: 137px;
  width: 150px;
  border: 1px dotted #3e3e3e;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
}

.file-upload-btn-main {
  position: relative;
  max-width: max-content;
}

.upload-profile-img {
  height: 137px;
  width: 150px;
  object-fit: cover;
  border-radius: 10px;
  /* background-image: url("../images/svg/No_image_available.svg.png"); */
}

.profile-upload-flex {
  display: flex;
}

.upload-input {
  margin-right: 15px;
}

.table-image {
  /* height: 35px;
  width: 100px;
  object-fit: contain; */
  height: 40px;
  width: 40px;
  object-fit: cover;
}

.upload-icon-img {
  height: 10px;
  width: 10px;
  object-fit: contain;
}

/* end file upload img css */

/* start switch css */

.switch-main {
  margin-left: 20px;
}

.switch-lable .MuiTypography-root {
  font-size: 14px;
  line-height: 21px;
  color: #9babc5;
  font-family: var(--body-text);
}

/* end switch css */

/* start chnage password page css */
.change-pass-main {
  border-radius: 15px;
  padding: 20px;
  max-width: 514px;
  width: 100%;
  background-color: #1e1e1e;
}

.change-pass-input-box .form-group .form-control input {
  background-color: #161616;
  border-radius: 22px;
  width: 100%;
  padding: 12px 15px;
}

.change-pass-flex {
  margin-bottom: 10px;
}

/* end chnage password page css */

/* start user list page */

.userdata-btn-flex {
  display: flex;
}

.userdata-btn-flex button {
  min-width: 30px;
}

.userlist-data {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.userlist-img {
  margin-right: 15px;
  height: 25px;
  width: 25px;
  object-fit: cover;
}

.userlist-table-main .table {
  margin: 0px;
  min-width: 1250px;
}

.userlist-table-main .table .table-th:nth-child(1),
.userlist-table-main .table .table-td:nth-child(1) {
  width: 2%;
}

.userlist-table-main .table .table-th:nth-child(2),
.userlist-table-main .table .table-td:nth-child(2) {
  width: 2%;
}

.userlist-table-main .table .table-th:nth-child(3),
.userlist-table-main .table .table-td:nth-child(3) {
  width: 7%;
}

.userlist-table-main .table .table-th:nth-child(4),
.userlist-table-main .table .table-td:nth-child(4) {
  width: 6%;
}

.userlist-table-main .table .table-th:nth-child(5),
.userlist-table-main .table .table-td:nth-child(5) {
  width: 9%;
}

.userlist-table-main .table .table-th:nth-child(6),
.userlist-table-main .table .table-td:nth-child(6) {
  width: 10%;
}

.userlist-table-main .table .table-th:nth-child(7),
.userlist-table-main .table .table-td:nth-child(7) {
  width: 6%;
}

.userlist-table-main .table .table-th:nth-child(8),
.userlist-table-main .table .table-td:nth-child(8) {
  width: 5%;
}

.userlist-table-main .table .table-th:nth-child(9),
.userlist-table-main .table .table-td:nth-child(9) {
  width: 5%;
}

.userlist-table-main .table .table-th:nth-child(10),
.userlist-table-main .table .table-td:nth-child(10) {
  width: 5%;
}

.down-icon,
.plus-icon {
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin-right: 9px;
}

.export-btn-main .export-btn,
.export-btn-main .export-btn:hover {
  margin-right: 10px;
  background-color: #26282d;
}

.adduser-btn-main .adduser-btn,
.adduser-btn-main .adduser-btn:hover {
  min-width: 115px;
}

.user-search-box {
  position: relative;
  margin-right: 10px;
}

.user-search-box .form-control input {
  padding: 9px 10px 9px 30px;
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-family: var(--body-text);
  min-width: 199px;
  background-color: #26282d;
}

.user-search-box .form-control input::placeholder {
  color: #4d586a;
  font-size: 12px;
  line-height: 15px;
  font-family: var(--body-text);
  opacity: 1;
}

.search-grey-img {
  position: absolute;
  left: 16px;
  top: 16px;
  height: 15px;
  width: 15px;
}

.user-list-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.userlist-btn-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-list-flex .user-list-page-title {
  margin-bottom: 0px;
}

/* end user list page */

/* start add user modal css */

.span-text {
  color: #4d586a;
  margin-left: 3px;
}

.modal .modal-title {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
  font-family: var(--body-text);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-close-icon {
  position: absolute;
  top: 28px;
  right: 20px;
  height: 13px;
  width: 13px;
  object-fit: contain;
}

.modal-user-btn-flex {
  display: flex;
  justify-content: flex-end;
  padding-top: 3px;
}

.modal .add-user-modal-inner-main {
  padding: 24px 24px 30px 24px;
  max-width: 500px;
  width: 100%;
  border-radius: 10px;
  background-color: #161616;
  position: relative;
}

.modal-input-box {
  margin-bottom: 24px;
}

.modal-input-box .form-group .form-control input {
  padding: 12px 15px;
  background-color: #1e1e1e;
}

/* end add user modal css */

/* start delete user modal css */

.user-circle-img {
  width: 52px;
  height: 52px;
  object-fit: contain;
  margin: auto;
  display: flex;
}

.modal-delete .delete-modal-inner-main {
  padding: 40px 24px 40px 24px;
  max-width: 500px;
  width: 100%;
  border-radius: 10px;
  background-color: #161616;
}

.delete-modal-inner-main .delete-modal-title {
  color: #fff;
  font-size: 28px;
  line-height: 42px;
  font-family: var(--body-text);
  text-align: center;
  margin: 20px 0px 16px;
}

.delete-modal-inner-main .delete-modal-para {
  color: #6b7280;
  margin-bottom: 26px;
  text-align: center;
  max-width: 304px;
  width: 100%;
  margin: auto;
}

.delete-modal-btn-flex {
  display: flex;
  justify-content: center;
  margin-top: 26px;
}

.delete-modal-btn-flex .modal-btn,
.delete-modal-btn-flex .modal-btn:hover {
  border-radius: 22px;
  max-width: 100px;
  width: 100%;
  padding: 8px;
  text-transform: capitalize;
  background-color: #26282d;
}

.delete-modal-btn-flex .modal-cancel-btn {
  color: #ffffff;
  margin-right: 20px;
}

.delete-modal-btn-flex .modal-delete-btn,
.delete-modal-btn-flex .modal-delete-btn:hover {
  color: #000000;
  background-image: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
}

.MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(35 35 35 / 80%);
}

/* end delete user modal css */

/* start header profile dropdown  */

.flex-drop-main .admin-header-drop {
  font-size: 12px;
  font-family: var(--body-text);
  line-height: 18px;
  color: #6b7280;
  text-align: start;
  margin-left: 12px;
  text-transform: capitalize;
}

.flex-drop-main .header-drop-title {
  font-family: var(--body-text);
}

.flex-drop-main {
  display: flex;
  align-items: center;
}

.drop-header {
  height: 15px;
  width: 15px;
  margin-right: 6px;
}

.drop-header-menu .MuiPaper-rounded {
  box-shadow: 0px 15px 20px #00000029;
  border-radius: 15px;
  margin-top: 18px;
  background-color: transparent;
}

.drop-header {
  height: 15px;
  width: 15px;
  margin-right: 6px;
}

.drop-header-menu .drop-header-menuitem {
  padding: 0px;
}

.drop-header-menu .drop-header-menuitemlink {
  font-size: 14px;
  line-height: 21px;
  color: #9babc5;
  font-family: var(--body-text);
  padding: 5px 16px;
}

.drop-header-menu ul {
  padding: 12px 11px;
  border-radius: 5px;
  background: var(--white);
  box-shadow: var(--box-shadow);
}

/* end header profile dropdown  */

.admin-sidebar-listitem .expandless-icon,
.admin-sidebar-listitem .expandmore-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.admin-submenu-listitem-main .admin-sidebar-link {
  display: block;
}

.admin-sidebar-submenulist .admin-sidebar-link {
  margin-left: 10px;
}

.admin-sidebar-submenulist .admin-sidebar-listitem:first-child .admin-sidebar-link {
  margin-top: 20px;
}

/* start pagination css */

.pagination-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
}

.pagination-main .MuiPaginationItem-root {
  height: 20px;
  min-width: 20px;
  border-radius: 4px;
  padding: 0px;
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  font-family: var(--body-text);
  margin: 0 4px;
}

.pagination-main .MuiPaginationItem-root.Mui-selected {
  background: transparent linear-gradient(90deg, #b21cd8 0%, #14f9ff 100%) 0% 0% no-repeat padding-box;
  color: #fff;
}

/* end pagination css */

/* start progrss bar css */

.progress-bar-main {
  max-width: 470px;
  width: 100%;
  position: relative;
}

.progress-bar-main .progress-bar .MuiLinearProgress-bar {
  border-radius: 8px;
  background-color: #114a65;
}

.progress-bar-content {
  margin-top: 30px;
}

.progress-bar-content .admin-page-title {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  font-family: var(--body-text);
}

.progress-bar-text {
  position: absolute;
  right: -35px;
  top: -5px;
  font-size: 14px;
  line-height: 21px;
  color: #6b7280;
  font-family: var(--body-text);
}

/* end progrss bar css */

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #3e3e3e !important;
}

.form-control-textarea:focus-visible {
  outline: none !important;
}

.bergur-btn-main {
  display: none;
}

.admin-sidebar-logo-main {
  display: none;
}

.dropdown-box svg {
  color: #fff;
}

.radio-main .MuiRadio-colorPrimary span {
  color: #9babc5;
}

/* start filter css */
.filter-main {
  position: relative;
}

.filter-main .MuiDrawer-paper {
  width: 100%;
  min-width: 400px;
  max-width: 400px;
  background-color: #161616 !important;
}

.filter-header {
  background-color: #26282d;
  display: flex;
  justify-content: space-between;
  padding: 16px 25px;
}

img.filter-close-icon {
  height: 21px;
  width: 21px;
  object-fit: contain;
}

.filter-header .filter-title {
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  font-family: var(--body-text);
}

.filter-inner-main {
  padding: 20px 25px;
}

.filter-btn-main {
  display: flex;
  justify-content: flex-end;
}

.filter-btn-main .filter-cancel-btn {
  min-width: 90px;
  height: 36px;
  margin-right: 20px;
}

.filter-btn-main .filter-btn,
.filter-btn-main .filter-btn:hover {
  max-width: 90px;
  height: 36px;
}

.filter-footer {
  border-top: 1px solid #3e3e3e;
  padding: 15px 25px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  right: 0px;
}

.filter-checkbox-main .checkbox-lable {
  margin-right: 20px;
}

/* end filter css */

/* start password input field css */

.input-box .form-control-eye {
  padding-right: 0px;
  border: 1px solid #3e3e3e;
}

.input-box .form-control-eye input {
  border: 0px;
}

.input-box .form-control-eye .toggle-eye-btn {
  margin-right: 0px;
}

.input-box .form-control-eye .toggle-eye-btn svg {
  color: #fff;
}

/* end password input field css */

/* start otp page */

.otp-input-box .form-group .form-control input {
  max-width: 70px;
  text-align: center;
  padding: 11px 15px;
}

.otp-input-box .form-group .MuiFormControl-root {
  max-width: 70px;
}

.otp-flex-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.otp-input-box {
  margin-right: 15px;
  margin-bottom: 10px;
}

.otp-flex-main .otp-input-box:last-child {
  margin-right: 0px;
}

/* end otp page */

/* start error text css */

div .Mui-error {
  /* color: red; */
  font-size: 12px;
  line-height: 15px;
  font-family: var(--body-text);
}

/* end error text css */

/* start page scrollbar scc */

.page-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* / / Track / / */
.page-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

/* / / Handle / / */
.page-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 8px;
}

/* / / Handle on hover / / */
.page-scrollbar::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

/* end page scrollbar scc */

/* Mui input */
.cust-otp-main .MuiOtpInput-Box input {
  background: #161616;
  color: #fff;
  border: 1px solid #3e3e3e;
  border-radius: 16px;
}

.genreslist-table-main .table .table-th:nth-child(1),
.genreslist-table-main .table .table-td:nth-child(1) {
  width: 5%;
}

.genreslist-table-main .table .table-th:nth-child(2),
.genreslist-table-main .table .table-td:nth-child(2) {
  width: 10%;
}

.genreslist-table-main .table .table-th:nth-child(3),
.genreslist-table-main .table .table-td:nth-child(3) {
  width: 10%;
}

.genreslist-table-main .table .table-th:nth-child(4),
.genreslist-table-main .table .table-td:nth-child(4) {
  width: 7%;
}

.genreslist-table-main .table .table-th:nth-child(5),
.genreslist-table-main .table .table-td:nth-child(5) {
  width: 7%;
}

.genreslist-table-main .table .table-th:nth-child(6),
.genreslist-table-main .table .table-td:nth-child(6) {
  width: 10%;
}

.genreslist-table-main .table .table-th:nth-child(7),
.genreslist-table-main .table .table-td:nth-child(7) {
  width: 1%;
}

.genreslist-table-main .table {
  margin: 0px;
  min-width: 1300px;
}

.addgame-list-table-main .table .table-th:nth-child(1),
.addgame-list-table-main .table .table-td:nth-child(1) {
  width: 5%;
}

.addgame-list-table-main .table .table-th:nth-child(2),
.addgame-list-table-main .table .table-td:nth-child(2) {
  width: 10%;
}

.addgame-list-table-main .table .table-th:nth-child(3),
.addgame-list-table-main .table .table-td:nth-child(3) {
  width: 10%;
}

.addgame-list-table-main .table .table-th:nth-child(4),
.addgame-list-table-main .table .table-td:nth-child(4) {
  width: 7%;
}

.addgame-list-table-main .table .table-th:nth-child(5),
.addgame-list-table-main .table .table-td:nth-child(5) {
  width: 7%;
}

.addgame-list-table-main .table .table-th:nth-child(6),
.addgame-list-table-main.table .table-td:nth-child(6) {
  width: 7%;
}

.addgame-list-table-main .table .table-th:nth-child(7),
.addgame-list-table-main .table .table-td:nth-child(7) {
  width: 1%;
}

.addgame-list-table-main .table {
  margin: 0px;
  min-width: 1300px;
}

.cms-page-content .cms-lables {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  font-family: var(--body-text);
  margin-bottom: 0px;
  color: #ffffff;
  margin-top: 30px;
}

.cms-input-box {
  margin: 10px 0px 30px 0px;
}

.cms-form-box {
  min-width: 300px;
  width: 100%;
  max-width: 300px;
}

.cms-page-containt .cms-discription-lables {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  font-family: var(--body-text);
  margin-bottom: 15px;
  color: #ffffff;
  margin-top: 0px;
}

.search-icon-box {
  position: absolute;
  left: 15px;
  top: 11px;
}

.images-upload-btn {
  position: relative;
  height: 100%;
}

.images-upload-btn .image-upload-btn,
.images-upload-btn .image-upload-btn:hover {
  background-color: #161616;
  padding: 0px;
  box-shadow: none;
  width: 100%;
  height: 100%;
  border: 1px dotted #3e3e3e;
  border-radius: 10px;
  min-height: 137px;
}

.upload-img {
  height: 30px;
  width: 30px;
  object-fit: contain;
  border-radius: 10px;
}

.upload-img-slider {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  height: 137px;
}

.document-close {
  position: absolute;
  background: white;
  cursor: pointer;
  border-radius: 50%;
  right: -6px;
  top: -8px;
  height: 20px;
  width: 20px;
}

.set-add-btn {
  display: flex;
  justify-content: flex-start;
}

.document-close-set {
  position: relative;
}

.edit-profile-btn-main {
  margin-top: 15px;
}

.userdata-btn-flex svg {
  color: #ffffffad;
}

.add-game-row {
  margin: 0px 0px 20px;
}

.search-icon {
  height: 12px;
  width: 12px;
  object-fit: contain;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cms-page-content .admin-page-title {
  margin-bottom: 40px;
}

.cms-page-content {
  padding-bottom: 17px;
}

.cms-page-row {
  margin: 0px 0px 10px;
}

.cms-page-containt {
  padding-bottom: 15px;
}

.admin-sidebar-dropdown {
  display: flex;
  align-items: "self-start";
}

.add-game-btn .save-user-btn {
  margin-left: 10px;
}

.user-back-icon {
  height: 4px;
  color: #fff;
}

.userlist-dialog-btn {
  background-color: #1976d2 !important;
  color: white !important;
}

.news-list-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inactive {
  color: #e2150e !important;
}

.Active {
  color: green !important;
}

.sidebar-slider-icons {
  color: white !important;
}

.game-list-table {
  align-items: center;
}

.rolelist-btn-flex {
  display: flex;
  justify-content: end;
}

/* rolelist-table-start */
.rolelist-table-main .table {
  min-width: 1100px;
}

.rolelist-table-main .table .table-th:nth-child(1),
.rolelist-table-main .table .table-td:nth-child(1) {
  width: 20%;
}

.rolelist-table-main .table .table-th:nth-child(2),
.rolelist-table-main .table .table-td:nth-child(2) {
  width: 20%;
}

.rolelist-table-main .table .table-th:nth-child(3),
.rolelist-table-main .table .table-td:nth-child(3) {
  width: 20%;
}

.rolelist-table-main .table .table-th:nth-child(4),
.rolelist-table-main .table .table-td:nth-child(4) {
  width: 20%;
}

/* rolelist-table-end */

.guide-edit-icon {
  height: 20px;
  color: whitesmoke;
}

.adminrolelist-table-main .table .table-th:nth-child(1),
.adminrolelist-table-main .table .table-td:nth-child(1) {
  width: 25%;
}

.adminrolelist-table-main .table .table-th:nth-child(2),
.adminrolelist-table-main .table .table-td:nth-child(2) {
  width: 30%;
}

.adminrolelist-table-main .table .table-th:nth-child(3),
.adminrolelist-table-main .table .table-td:nth-child(3) {
  width: 35%;
}

.adminrolelist-table-main .table .table-th:nth-child(4),
.adminrolelist-table-main .table .table-td:nth-child(4) {
  width: 35%;
}

/* sub-admin-table-start */
.subadmin-table-main .table {
  min-width: 1200px;
}

.subadmin-table-main .table .table-th:nth-child(1),
.subadmin-table-main .table .table-td:nth-child(1) {
  width: 3%;
}

.subadmin-table-main .table .table-th:nth-child(2),
.subadmin-table-main .table .table-td:nth-child(2) {
  width: 3%;
}

.subadmin-table-main .table .table-th:nth-child(3),
.subadmin-table-main .table .table-td:nth-child(3) {
  width: 10%;
}

.subadmin-table-main .table .table-th:nth-child(4),
.subadmin-table-main .table .table-td:nth-child(4) {
  width: 5%;
}

.subadmin-table-main .table .table-th:nth-child(5),
.subadmin-table-main .table .table-td:nth-child(5) {
  width: 5%;
}

.subadmin-table-main .table .table-th:nth-child(6),
.subadmin-table-main .table .table-td:nth-child(6) {
  width: 5%;
}

.subadmin-table-main .table .table-th:nth-child(7),
.subadmin-table-main .table .table-td:nth-child(7) {
  width: 5%;
}

.subadmin-table-main .table .table-th:nth-child(8),
.subadmin-table-main .table .table-td:nth-child(8) {
  width: 5%;
}

.subadmin-table-main .table .table-th:nth-child(9),
.subadmin-table-main .table .table-td:nth-child(9) {
  width: 5%;
}

/* end-subadmin-table */

.role-addrole-icons {
  margin-right: 10px;
}

.admin-guide-sidebar-icons {
  height: 25px;
  width: 25px;
}

.sociallinks-btn-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ckeditor-error {
  color: #d32f2f !important;
  font-size: 12px;
  line-height: 15px;
  font-family: var(--body-text);
  margin-top: 4px;
}

.ck-editor__editable_inline {
  min-height: 250px;
}

.ck-editor__editable ul li {
  margin: 0px 15px !important;
}

.ck-editor__editable ol li {
  margin: 0px 15px !important;
}

.userlist-table-main .table .table-td.reviewlist-td {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  max-height: 55px;
}

.form-grp-upload-cover {
  width: 274px;
}

.form-grp-upload-cover .images-upload-btn .image-upload-btn {
  min-width: 137px;
  padding: 0px;
}

.user-delete-dialong .MuiPaper-root {
  margin: 0px;
  border-radius: 10px;
  background-color: #1e1e1e;
  padding: 20px;
}

.user-delete-dialong .userlist-dialog-text {
  color: #fff;
  font-size: 19px;
  line-height: 28px;
  font-family: var(--body-text);
  margin-bottom: 0px;
  text-align: center;
}

.user-delete-dialong .MuiDialogContent-root {
  padding: 0px;
}

.user-delete-dialong .dialog-btn-main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.user-delete-dialong .dialog-lable-text {
  font-size: 14px;
  line-height: 25px;
  font-family: var(--body-text);
  color: #fff;
  word-break: break-word;
}

.user-delete-dialong .dialog-close-btn-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.table-th .check-box-role {
  color: rgba(255, 255, 255, 0.473);

  padding: 0px;
}

.table-body .check-box-role {
  color: rgba(255, 255, 255, 0.473);
  padding: 0px;
}

.table-body .check-box-role svg {
  /* color: white; */

  padding: 0px;
  /* fill: blue */
}

/* .table-body .check-box-role svg:checked {
  fill: #7b5ee6;
  fill-opacity: 0.3;
} */

.genres-list-table-main .table {
  min-width: 1100px;
}

.genres-list-table-main .table .table-th:nth-child(1),
.genres-list-table-main .table .table-td:nth-child(1) {
  width: 2%;
}

.genres-list-table-main .table .table-th:nth-child(2),
.genres-list-table-main .table .table-td:nth-child(2) {
  width: 2%;
}

.genres-list-table-main .table .table-th:nth-child(3),
.genres-list-table-main .table .table-td:nth-child(3) {
  width: 2%;
}

.genres-list-table-main .table .table-th:nth-child(4),
.genres-list-table-main .table .table-td:nth-child(4) {
  width: 4%;
}

.genres-list-table-main .table .table-th:nth-child(5),
.genres-list-table-main .table .table-td:nth-child(5) {
  width: 4%;
}

.genres-list-table-main .table .table-th:nth-child(6),
.genres-list-table-main .table .table-td:nth-child(6) {
  width: 2%;
}

.genres-list-table-main .table .table-th:nth-child(7),
.genres-list-table-main .table .table-td:nth-child(7) {
  width: 5%;
}

.addgame-list-table-main .table {
  min-width: 1100px;
}

.addgame-list-table-main .table .table-th:nth-child(1),
.addgame-list-table-main .table .table-td:nth-child(1) {
  width: 3%;
}

.addgame-list-table-main .table .table-th:nth-child(2),
.addgame-list-table-main .table .table-td:nth-child(2) {
  width: 7%;
}

.addgame-list-table-main .table .table-th:nth-child(3),
.addgame-list-table-main .table .table-td:nth-child(3) {
  width: 7%;
}

.addgame-list-table-main .table .table-th:nth-child(4),
.addgame-list-table-main .table .table-td:nth-child(4) {
  width: 7%;
}

.addgame-list-table-main .table .table-th:nth-child(5),
.addgame-list-table-main .table .table-td:nth-child(5) {
  width: 7%;
}

.addgame-list-table-main .table .table-th:nth-child(6),
.addgame-list-table-main .table .table-td:nth-child(6) {
  width: 7%;
}

.addgame-list-table-main .table .table-th:nth-child(7),
.addgame-list-table-main .table .table-td:nth-child(7) {
  width: 7%;
}

.network-list-table-main .table {
  min-width: 1000px;
}

.network-list-table-main .table .table-th:nth-child(1),
.network-list-table-main .table .table-td:nth-child(1) {
  width: 3%;
}

.network-list-table-main .table .table-th:nth-child(2),
.network-list-table-main .table .table-td:nth-child(2) {
  width: 4%;
}

.network-list-table-main .table .table-th:nth-child(3),
.network-list-table-main .table .table-td:nth-child(3) {
  width: 7%;
}

.network-list-table-main .table .table-th:nth-child(4),
.network-list-table-main .table .table-td:nth-child(4) {
  width: 7%;
}

.network-list-table-main .table .table-th:nth-child(5),
.network-list-table-main .table .table-td:nth-child(5) {
  width: 7%;
}

.network-list-table-main .table .table-th:nth-child(6),
.network-list-table-main .table .table-td:nth-child(6) {
  width: 7%;
}

.news-list-table-main .table {
  min-width: 1000px;
}

.news-list-table-main .table .table-th:nth-child(1),
.news-list-table-main .table .table-td:nth-child(1) {
  width: 8%;
}

.news-list-table-main .table .table-th:nth-child(2),
.news-list-table-main .table .table-td:nth-child(2) {
  width: 7%;
}

.news-list-table-main .table .table-th:nth-child(3),
.news-list-table-main .table .table-td:nth-child(3) {
  width: 13%;
}

.news-list-table-main .table .table-th:nth-child(4),
.news-list-table-main .table .table-td:nth-child(4) {
  width: 15%;
}

.news-list-table-main .table .table-th:nth-child(5),
.news-list-table-main .table .table-td:nth-child(5) {
  width: 15%;
}

.news-list-table-main .table .table-th:nth-child(6),
.news-list-table-main .table .table-td:nth-child(6) {
  width: 15%;
}

.news-list-table-main .table .table-th:nth-child(7),
.news-list-table-main .table .table-td:nth-child(7) {
  width: 10%;
}

/* rews */

.rew-list-table-main .table {
  min-width: 1000px;
}

.rew-list-table-main .table .table-th:nth-child(1),
.rew-list-table-main .table .table-td:nth-child(1) {
  width: 3%;
}

.rew-list-table-main .table .table-th:nth-child(2),
.rew-list-table-main .table .table-td:nth-child(2) {
  width: 3%;
}

.rew-list-table-main .table .table-th:nth-child(3),
.rew-list-table-main .table .table-td:nth-child(3) {
  width: 6%;
}

.rew-list-table-main .table .table-th:nth-child(4),
.rew-list-table-main .table .table-td:nth-child(4) {
  width: 6%;
}

.rew-list-table-main .table .table-th:nth-child(5),
.rew-list-table-main .table .table-td:nth-child(5) {
  width: 10%;
}

.rew-list-table-main .table .table-th:nth-child(6),
.rew-list-table-main .table .table-td:nth-child(6) {
  width: 8%;
}

.rew-list-table-main .table .table-th:nth-child(7),
.rew-list-table-main .table .table-td:nth-child(7) {
  width: 10%;
}

.rew-list-table-main .table .table-th:nth-child(8),
.rew-list-table-main .table .table-td:nth-child(8) {
  width: 8%;
}

.rew-list-table-main .table .table-th:nth-child(9),
.rew-list-table-main .table .table-td:nth-child(9) {
  width: 5%;
}

.add-user-data-main .form-lable {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  font-family: var(--body-text);
  margin-bottom: 6px;
  color: #ffffff;
  margin-top: 0px;
}

/* end Rews */

.review-list-table-main .table {
  min-width: 1500px;
}

.review-list-table-main .table .table-th:nth-child(1),
.review-list-table-main .table .table-td:nth-child(1) {
  width: 3%;
}

.review-list-table-main .table .table-th:nth-child(2),
.review-list-table-main .table .table-td:nth-child(2) {
  width: 4%;
}

.review-list-table-main .table .table-th:nth-child(3),
.review-list-table-main .table .table-td:nth-child(3) {
  width: 6%;
}

.review-list-table-main .table .table-th:nth-child(4),
.review-list-table-main .table .table-td:nth-child(4) {
  width: 5%;
}

.review-list-table-main .table .table-th:nth-child(5),
.review-list-table-main .table .table-td:nth-child(5) {
  width: 5%;
}

.review-list-table-main .table .table-th:nth-child(6),
.review-list-table-main .table .table-td:nth-child(6) {
  width: 5%;
}

.review-list-table-main .table .table-th:nth-child(7),
.review-list-table-main .table .table-td:nth-child(7) {
  width: 5%;
}

.review-list-table-main .table .table-th:nth-child(8),
.review-list-table-main .table .table-td:nth-child(8) {
  width: 7%;
}

.slider-list-table-main .table {
  min-width: 1000px;
}

.slider-list-table-main .table .table-th:nth-child(1),
.slider-list-table-main .table .table-td:nth-child(1) {
  width: 7%;
}

.slider-list-table-main .table .table-th:nth-child(2),
.slider-list-table-main .table .table-td:nth-child(2) {
  width: 7%;
}

.slider-list-table-main .table .table-th:nth-child(3),
.slider-list-table-main .table .table-td:nth-child(3) {
  width: 20%;
}

.slider-list-table-main .table .table-th:nth-child(4),
.slider-list-table-main .table .table-td:nth-child(4) {
  width: 20%;
}

.slider-list-table-main .table .table-th:nth-child(5),
.slider-list-table-main .table .table-td:nth-child(5) {
  width: 20%;
}

.slider-list-table-main .table .table-th:nth-child(6),
.slider-list-table-main .table .table-td:nth-child(6) {
  width: 15%;
}

.slider-list-table-main .table .table-th:nth-child(7),
.slider-list-table-main .table .table-td:nth-child(7) {
  width: 15%;
}

/* games-table start*/

.games-list-table-main .table {
  min-width: 900px;
}

.games-list-table-main .table .table-th:nth-child(1),
.games-list-table-main .table .table-td:nth-child(1) {
  width: 3%;
}

.games-list-table-main .table .table-th:nth-child(2),
.games-list-table-main .table .table-td:nth-child(2) {
  width: 3%;
}

.games-list-table-main .table .table-th:nth-child(3),
.games-list-table-main .table .table-td:nth-child(3) {
  width: 5%;
}

.games-list-table-main .table .table-th:nth-child(4),
.games-list-table-main .table .table-td:nth-child(4) {
  width: 8%;
}

.games-list-table-main .table .table-th:nth-child(5),
.games-list-table-main .table .table-td:nth-child(5) {
  width: 10%;
}

.games-list-table-main .table .table-th:nth-child(6),
.games-list-table-main .table .table-td:nth-child(6) {
  width: 7%;
}

.games-list-table-main .table .table-th:nth-child(7),
.games-list-table-main .table .table-td:nth-child(7) {
  width: 7%;
}

.games-list-table-main .table .table-th:nth-child(8),
.games-list-table-main .table .table-td:nth-child(8) {
  width: 5%;
}

/* games-table end */

/* status-table */

.status-list-table-main .table {
  min-width: 1000px;
}

.status-list-table-main .table .table-th:nth-child(1),
.status-list-table-main .table .table-td:nth-child(1) {
  width: 25%;
}

.status-list-table-main .table .table-th:nth-child(2),
.status-list-table-main .table .table-td:nth-child(2) {
  width: 25%;
}

.status-list-table-main .table .table-th:nth-child(3),
.status-list-table-main .table .table-td:nth-child(3) {
  width: 25%;
}

.status-list-table-main .table .table-th:nth-child(4),
.status-list-table-main .table .table-td:nth-child(4) {
  width: 25%;
}

/* status-table-end */

/* investor-table-start */

.investor-list-table-main .table .table-th:nth-child(1),
.investor-list-table-main .table .table-td:nth-child(1) {
  width: 2%;
}

.investor-list-table-main .table .table-th:nth-child(2),
.investor-list-table-main .table .table-td:nth-child(2) {
  width: 2%;
}

.investor-list-table-main .table .table-th:nth-child(3),
.investor-list-table-main .table .table-td:nth-child(3) {
  width: 5%;
}

.investor-list-table-main .table .table-th:nth-child(4),
.investor-list-table-main .table .table-td:nth-child(4) {
  width: 3%;
}

.investor-list-table-main .table .table-th:nth-child(5),
.investor-list-table-main .table .table-td:nth-child(5) {
  width: 3%;
}

.investor-list-table-main .table .table-th:nth-child(6),
.investor-list-table-main .table .table-td:nth-child(6) {
  width: 3%;
}

.investor-list-table-main .table .table-th:nth-child(7),
.investor-list-table-main .table .table-td:nth-child(7) {
  width: 3%;
}

/* ivestor-list-end */

.guide-list-table-main .table {
  min-width: 900px;
}

.guide-list-table-main .table .table-th:nth-child(1),
.guide-list-table-main .table .table-td:nth-child(1) {
  width: 7%;
}

.guide-list-table-main .table .table-th:nth-child(2),
.guide-list-table-main .table .table-td:nth-child(2) {
  width: 7%;
}

.guide-list-table-main .table .table-th:nth-child(3),
.guide-list-table-main .table .table-td:nth-child(3) {
  width: 15%;
}

.guide-list-table-main .table .table-th:nth-child(4),
.guide-list-table-main .table .table-td:nth-child(4) {
  width: 15%;
}

.guide-list-table-main .table .table-th:nth-child(5),
.guide-list-table-main .table .table-td:nth-child(5) {
  width: 15%;
}

.guide-list-table-main .table .table-th:nth-child(6),
.guide-list-table-main .table .table-td:nth-child(6) {
  width: 15%;
}

.guide-list-table-main .table .table-th:nth-child(7),
.guide-list-table-main .table .table-td:nth-child(7) {
  width: 15%;
}

.editor-choice-table-main .table {
  min-width: 1000px;
}

.editor-choice-table-main .table .table-th:nth-child(1),
.editor-choice-table-main .table .table-td:nth-child(1) {
  width: 20%;
}

.editor-choice-table-main .table .table-th:nth-child(2),
.editor-choice-table-main .table .table-td:nth-child(2) {
  width: 20%;
}

.editor-choice-table-main .table .table-th:nth-child(3),
.editor-choice-table-main .table .table-td:nth-child(3) {
  width: 20%;
}

.editor-choice-table-main .table .table-th:nth-child(4),
.editor-choice-table-main .table .table-td:nth-child(4) {
  width: 20%;
}

.editor-choice-table-main .table .table-th:nth-child(5),
.editor-choice-table-main .table .table-td:nth-child(5) {
  width: 20%;
}

.editor-choice-table-main .table .table-th:nth-child(6),
.editor-choice-table-main .table .table-td:nth-child(6) {
  width: 5%;
}

.add-user-input .custom-date-picker {
  width: 100%;
}

.add-user-input .custom-date-picker .MuiInputBase-root {
  padding: 0px;
}

.add-user-input .custom-date-picker .MuiButtonBase-root {
  position: absolute;
  right: 30px;
  top: 10px;
  margin: 0px;
  padding: 0px;
}

.add-user-input .custom-date-picker .MuiButtonBase-root[aria-label="Choose date"] {
  color: #ffffff;
}

.vr-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
  margin-left: 5px;
}

.platform-list-table-main .table {
  min-width: 1000px;
}

.platform-list-table-main .table .table-th:nth-child(1),
.platform-list-table-main .table .table-td:nth-child(1) {
  width: 20%;
}

.platform-list-table-main .table .table-th:nth-child(2),
.platform-list-table-main .table .table-td:nth-child(2) {
  width: 20%;
}

.platform-list-table-main .table .table-th:nth-child(3),
.platform-list-table-main .table .table-td:nth-child(3) {
  width: 20%;
}

.platform-list-table-main .table .table-th:nth-child(4),
.platform-list-table-main .table .table-td:nth-child(4) {
  width: 20%;
}

.platform-list-table-main .table .table-th:nth-child(5),
.platform-list-table-main .table .table-td:nth-child(5) {
  width: 20%;
}

.event-list-table-main .table {
  min-width: 1000px;
}

.event-list-table-main .table .table-th:nth-child(1),
.event-list-table-main .table .table-td:nth-child(1) {
  width: 6%;
}

.event-list-table-main .table .table-th:nth-child(2),
.event-list-table-main .table .table-td:nth-child(2) {
  width: 5%;
}

.event-list-table-main .table .table-th:nth-child(3),
.event-list-table-main .table .table-td:nth-child(3) {
  width: 10%;
}

.event-list-table-main .table .table-th:nth-child(4),
.event-list-table-main .table .table-td:nth-child(4) {
  width: 15%;
}

.event-list-table-main .table .table-th:nth-child(5),
.event-list-table-main .table .table-td:nth-child(5) {
  width: 15%;
}

.event-list-table-main .table .table-th:nth-child(6),
.event-list-table-main .table .table-td:nth-child(6) {
  width: 15%;
}

.event-list-table-main .table .table-th:nth-child(7),
.event-list-table-main .table .table-td:nth-child(7) {
  width: 10%;
}

.event-list-table-main .table .table-th:nth-child(8),
.event-list-table-main .table .table-td:nth-child(8) {
  width: 10%;
}

.noti-list-table-main .table {
  min-width: 1000px;
}

.noti-list-table-main .table .table-th:nth-child(1),
.noti-list-table-main .table .table-td:nth-child(1) {
  width: 20%;
}

.noti-list-table-main .table .table-th:nth-child(2),
.noti-list-table-main .table .table-td:nth-child(2) {
  width: 20%;
}

.noti-list-table-main .table .table-th:nth-child(3),
.noti-list-table-main .table .table-td:nth-child(3) {
  width: 20%;
}

.noti-list-table-main .table .table-th:nth-child(4),
.noti-list-table-main .table .table-td:nth-child(4) {
  width: 20%;
}

.noti-list-table-main .table .table-th:nth-child(5),
.noti-list-table-main .table .table-td:nth-child(5) {
  width: 20%;
}

/* .noti-list-table-main .table .table-th:nth-child(6),
.noti-list-table-main .table .table-td:nth-child(6) {
  width: 15%;
}  */
.display-row-noti-user {
  align-items: center;
}

.user-noti-flex {
  display: flex;
  width: 100%;
  align-items: center;
}

.noti-btns-main {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.name-with-icon {
  display: flex;
}

.role-add-table-main .table {
  min-width: initial;
}

.role-add-table-main .table .table-th:nth-child(1),
.role-add-table-mainn .table .table-td:nth-child(1) {
  width: 15%;
}

.role-add-table-main .table .table-th:nth-child(2),
.role-add-table-main .table .table-td:nth-child(2) {
  width: 15%;
}

.role-add-table-main .table .table-th:nth-child(3),
.role-add-table-main .table .table-td:nth-child(3) {
  width: 15%;
}

.role-add-table-main {
  margin: 15px 0px;
  border: 1px solid #3e3e3e;
  padding: 15px 15px 0px 15px;
  border-radius: 15px;
}

.not-found-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #fff;
  font-family: var(--body-text);
  max-width: 100%;
  margin: auto;
}

.user-delete-btn-main {
  margin-right: 10px;
}

.userdata-btn-flex .MuiIconButton-root {
  padding: 0px;
}

.role-delete-btn-main {
  display: flex;
  justify-content: flex-end;
}

.table-data-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  min-height: 21px;
  max-width: 200px;
  word-break: break-word;
  width: 100%;
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #171c3600 inset;
  transition: background-color 5000s ease-in-out 0s;
  border-radius: 22px !important;
  /* -webkit-text-fill-color: #fff; */
}

.role-card-main {
  width: 100%;
  background-color: #1e1e1e;
  border-radius: 11px;
  padding: 15px;
}

.role-titles-main .role-titles {
  font-size: 14px;
  line-height: 21px;
  font-family: var(--body-text);
  margin-bottom: 0px;
  color: #ffffff;
}

.roles-data-box {
  max-width: 100px;
  flex: 0 0 100px;
  width: 100%;
}

.add-role-flex {
  display: flex;
}

.roles-data-box .role-data-title {
  font-size: 13px;
  line-height: 19px;
  color: #9babc5;
  font-family: var(--body-text);
}

.role-card-inner-flex {
  display: flex;
}

.roles-data-main-flex {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.role-titles-main {
  max-width: 50%;
  width: 100%;
  flex: 0 0 50%;
}

.roles-data-box .check-box-role svg {
  color: #9babc5;
  height: 16px;
  width: 16px;
  margin-right: 5px;
}

.roles-data-box .check-box-role {
  padding: 0px;
}

.add-role-row {
  margin-bottom: 20px;
}

.loader-main {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent linear-gradient(180deg, #161616 0%, #1e1e1e 100%);
  height: 100vh;
  width: 100%;
  /* position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10000; */
}

.loader-img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  filter: invert(0.5);
}

.otp-box-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.otp-box-main .mui-otp-box {
  background: #161616;
  color: #fff;
  border: 1px solid #3e3e3e;
  border-radius: 16px;
}

.role-total-user-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-rolelist-row {
  margin-bottom: 20px;
}

.role-total-user-flex .role-totle-user-text {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  font-family: var(--body-text);
  margin-bottom: 6px;
  color: #ffffff;
  margin-top: 0px;
}

.role-user-name-box .role-user-name {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0px;
  font-family: var(--body-text);
  margin-bottom: 0px;
  text-transform: capitalize;
  color: #ffffff;
}

.edit-role-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px 0px;
}

.edit-role-flex .edit-role-text {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  font-family: var(--body-text);
  margin-bottom: 6px;
  color: #9babc5;
  margin-top: 0px;
}

.role-user-img-flex .user-avtar-group-main .role-user-imges {
  border: 1px solid #fff;
  height: 30px;
  width: 30px;
}

.role-user-imges .MuiAvatar-img {
  height: 30px;
  width: 30px;
  object-fit: contain;
}

.role-user-name-box {
  margin: 10px 0px;
}

.user-avtar-group-main .MuiAvatar-circular {
  height: 30px;
  border: 1px solid #fff !important;
  width: 30px;
  font-size: 12px;
  line-height: 17px;
  font-family: var(--body-text);
  background-color: #161616;
  margin-left: -4px !important;
}

.edit-role-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
  cursor: pointer;
}

.add-role-more-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.role-more-img {
  height: 100px;
  width: 100px;
  object-fit: contain;
}

.sub-title-main .sub-title-text {
  color: #fff;
  font-size: 24px;
  line-height: 32px;
  font-family: var(--body-text);
  margin-bottom: 25px;
}

.checkbox-flex {
  display: flex;
  margin-top: 37px;
}

.checkbox-flex .checkbox-main:first-child {
  margin-right: 20px;
}

.page-inner-box {
  margin: 25px 0px;
}

.date-picker-form-group .MuiInputBase-root .MuiInputAdornment-root {
  margin: 0px;
  position: absolute;
  right: 17px;
}

.date-picker-form-group .MuiInputBase-root {
  padding-right: 0px;
}

.date-picker-form-group .MuiStack-root>.MuiTextField-root {
  min-width: 100%;
}

.date-picker-form-group .MuiStack-root .MuiButtonBase-root {
  color: #9babc5;
}

.date-picker-form-group .MuiStack-root {
  padding: 0px;
}

.plus-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plus-icon-box .plus-icon-btn,
.plus-icon-box .plus-icon-btn:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #161616;
  height: 40px;
  width: 100%;
  min-width: 40px;
  border-radius: 5px;
}

.plus-icon-box .plus-icon-img {
  height: 25px;
  width: 20px;
  object-fit: contain;
}

.flex-end-admin-btn {
  display: flex;
  justify-content: end;
}

.add-remove-position .btn-plus-top-admin {
  position: absolute;
  right: 0;
  top: 0px;
}

.add-remove-position {
  position: relative;
}

.add-remove-position .btn-pos-top-quest {
  position: absolute;
  right: 0;
  top: 0px;
}

.add-remove-position .admin-minus-icon {
  height: 14px;
  width: 14px;
}

.dashboard-content .page-title-text {
  margin-bottom: 0px;
}

.form-group .form-control input.Mui-disabled,
textarea:disabled {
  color: #ffffff73;
  -webkit-text-fill-color: #ffffff73;
}

.MuiMenu-list {
  max-height: 300px;
  overflow: auto;
}

/* width */
.MuiMenu-list::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.MuiMenu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.MuiMenu-list::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.MuiMenu-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiMenu-list .menuitem {
  max-width: 370px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.game-dropdown .MuiMenu-list .game-menu-list {
  max-width: auto !important;
}

.tab-view-quests {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reward-form {
  /* display: block !important; */
  flex: 1;
}

.ck.ck-toolbar.ck-toolbar_grouping {
  background: #161616;
  border: 1px solid #3e3e3e;
}

.ck.ck-button.ck-on,
.ck-custom-editor-main a.ck.ck-button.ck-on {
  background: #161616;
}

.ck-custom-editor-main .ck.ck-list {
  background: #161616;
}

.ck.ck-dropdown__panel {
  border-color: #3e3e3e;
  background-color: #161616;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused),
.ck.ck-editor__main>.ck-editor__editable.ck-focused {
  background: #161616;
  border-color: #3e3e3e;
  color: #fff;
  min-height: 300px;
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  color: #fff !important;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: #161616;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background: #161616;
}

ul.ck.ck-reset.ck-list {
  background: #161616;
}

.ck.ck-list__item .ck-button.ck-on {
  background: #1e1e1e;
}

.ck.ck-list__item .ck-button.ck-on:focus:not(.ck-switchbutton):not(.ck-disabled) {
  border: 1px solid #3e3e3e;
  box-shadow: none;
}

.ck.ck-dropdown .ck-button.ck-dropdown__button.ck-off:active,
.ck.ck-dropdown .ck-button.ck-dropdown__button.ck-on:active {
  box-shadow: none !important;
  border: none;
  background: #161616;
  color: white;
}

.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  color: #ffffff;
}

.flex-div {
  display: flex;
  justify-content: space-between;
}

.ck.ck-toolbar.ck-toolbar_grouping {
  background: #161616;
  border: 1px solid #3e3e3e;
}

.ck.ck-button.ck-on,
.ck-custom-editor-main a.ck.ck-button.ck-on {
  background: #161616;
}

.ck-custom-editor-main .ck.ck-list {
  background: #161616;
}

.ck.ck-dropdown__panel {
  border-color: #3e3e3e;
  background-color: #161616;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused),
.ck.ck-editor__main>.ck-editor__editable.ck-focused {
  background: #161616;
  border-color: #3e3e3e;
  color: #fff;
  min-height: 300px;
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  color: #fff;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: #161616;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background: #161616;
}

.ck.ck-dropdown .ck-button.ck-dropdown__button.ck-off:active,
.ck.ck-dropdown .ck-button.ck-dropdown__button.ck-on:active {
  box-shadow: none !important;
  border: none;
  background: #161616;
  color: white;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover,
.ck.ck-list__item .ck-button:hover:not(.ck-disabled) {
  background: #161616;
}

.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  color: #ffffff;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover,
.ck.ck-button.ck-on:not(.ck-disabled):hover,
a.ck.ck-button.ck-on:not(.ck-disabled):hover,
.ck.ck-labeled-field-view>.ck.ck-labeled-field-view__input-wrapper>.ck.ck-label {
  background: #161616;
}

.ck.ck-balloon-panel {
  background: #161616;
  border: var(--ck-balloon-border-width) solid #3e3e3e;
}

.ck.ck-balloon-panel[class*="arrow_n"]:after {
  border-color: transparent transparent #161616 transparent;
  margin-top: 0px;
}

.ck.ck-content a {
  color: #fff;
}

.ck.ck-input {
  background: #1e1e1e;
  border: 1px solid #3e3e3e;
}

.table-data-text img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  margin-right: 10px;
  display: none;
}

.table-data-text figure {
  height: 40px;
  width: 40px;
  object-fit: cover;
  margin-right: 10px;
  display: none;
}

.table-data-text table {
  height: 40px;
  width: 40px;
  object-fit: cover;
  margin-right: 10px;
  display: none;
}

.table-data-text .vr-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background-color: transparent;
}

.userlist-dialog-text img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  max-width: 523px;
}

.user-delete-dialong::-webkit-scrollbar {
  width: 3px;
}

.user-delete-dialong::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.user-delete-dialong::-webkit-scrollbar-thumb {
  background: #888;
}

.user-delete-dialong::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-recaptcha {
  margin-bottom: 12px;
  margin-left: 41px;
  margin-top: 15px;
}

.error-text {
  color: #c82622ed !important;
  font-size: 12px !important;
  line-height: 15px !important;
  font-family: var(--body-text) !important;
  margin: 3px 0px 0px !important;
}

.table-data-text a {
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  font-family: var(--body-text);
  text-decoration: none;
}

.offering-cus-table .offering-table-body {
  padding: 15px;
  border: 1px solid var(--table-border);
  border-top: none;
  border-radius: 0 0 15px 15px;
}

.offering-cus-table .offering-table-head {
  background: var(--gradient);
  border-radius: 15px 15px 0 0;
  padding: 11px 20px;
  display: flex;
  /* justify-content: space-between; */
}

.offering-cus-table .th-blank {
  text-align: center;
  width: 8%;
}

.offering-cus-table .th-offe-type {
  text-align: center;
  width: 32%;
}

.width-new {
  width: 10%;
}

.offering-cus-table .th-quantity {
  text-align: center;
  width: 15%;
}

.offering-cus-table .th-offe-price {
  text-align: center;
  width: 15%;
}

.offering-cus-table .th-offe-raise {
  text-align: center;
  width: 15%;
}

.offering-cus-table .th-action {
  text-align: center;
  width: 15%;
}

.offering-cus-table .offering-th {
  color: var(--white);
  font-family: var(--head-text);
  font-size: 12px;
  line-height: 27px;
  min-width: 29px;
  text-transform: uppercase;
}

.offering-tr .offering-tr-main {
  padding: 10px;
}

.offering-tr-main .lock-main {
  width: 30px;
  height: 30px;
}

.offering-list {
  width: 60%;
  display: flex;
}

.gradient-inner-box {
  background: #040b28;
  background: var(--darkBlue);
  height: 100%;
  width: 100%;
}

.gradient-box,
.gradient-inner-box {
  border-radius: 5px;
  border-radius: var(--border-radius-5);
}

.offering-tr .offering-th-text {
  color: var(--black);
  font-family: var(--body-text);
  font-size: 12px;
  line-height: 27px;
  font-weight: 600;
}

.css-18etzq8 {
  margin: 0px;
  color: rgb(24, 191, 46) !important;
}

.gradient-box,
.gradient-inner-box {
  border-radius: 5px;
  border-radius: var(--border-radius-5);
}

.space-between {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.cus-center,
.d-flex {
  display: flex !important;
}

.cus-center,
.d-flex {
  display: flex !important;
}

.offering-list .offering-td-box {
  height: 44px;
  width: 100%;
}

.cus-center {
  align-items: center;
  justify-content: center;
}

.gradient-inner-box {
  background: #040b28;
  background: var(--darkBlue);
  height: 100%;
  width: 100%;
}

.gradient-box {
  background: linear-gradient(99deg, #ff387a, #4845ff);
  background: linear-gradient(99deg, #ff387a, #4845ff);
  background: var(--gradient);
  padding: 1px;
}

.presale-wraper {
  width: 100%;
  padding: 15px;
  background: var(--white);
}

.crtl-wrape {
  background: linear-gradient(99deg, #ff387a, #4845ff);
  background: linear-gradient(99deg, #ff387a, #4845ff);
  background: var(--gradient);
  padding: 1px;
  border-radius: 15px;
  margin-top: 10px;
}

.crtl-wrape .inner-pad {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: var(--white);
  height: 100%;
  width: 100%;
  padding: 10px;
  border-radius: 14px;
}

.crtl-wrape .inner-pad2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--white);
  height: 100%;
  width: 100%;
  padding: 15px;
  border-radius: 14px;
}

.crtl-wrape .inner-pad2 p.mint-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.crtl-wrape .inner-pad2 p {
  font-family: var(--head-text);
  font-size: 17px;
  margin-bottom: 5px;
}

.crtl-wrape .inner-pad2 p.Mui-error {
  font-size: 13px;
  color: red;
}

.home-input-main .form-control input {
  border: 0px !important;
  text-align: left;
  font-size: 20px !important;
  line-height: 25px !important;
  color: #fff !important;
}

.home-input-main .form-control input::placeholder {
  font-size: 20px !important;
  line-height: 25px !important;
  color: #fff !important;
}

.home-input-main .form-control fieldset {
  border: 0px !important;
}

.primary-btn-main .primary-btn,
.primary-btn-main .primary-btn:hover {
  background: var(--blue);
  border-radius: 20px;
  width: 100%;
  color: var(--white);
  font-size: 14px;
  line-height: 16px;
  padding: 12px 30px;
  text-transform: uppercase;
  font-weight: 400;
}

.input-fild {
  padding: 0 !important;
  height: 42px;
  width: 100%;
  color: black !important;
  border-radius: 8px;
  border: 1px solid var(--input-border) !important;
  margin-bottom: 23px !important;
}

.input-fild input {
  padding: 9px 10px;
  font-size: 15px;
}

.offering-list .inner-wrape {
  display: flex;
  width: 100%;
  gap: 15px;
  justify-content: space-between;
}

.offering-table-head .offering-list .inner-wrape {
  padding: 0px 11px 0px 0;
}

.offering-list.table-head-adjust {
  padding-left: 20px;
}

.btn-like {
  background: linear-gradient(109deg, #ff387a 0%, #4845ff 100%) 0% 0%;
  border-radius: 15px;
  width: 100%;
  color: var(--white);
  font-size: 16px;
  line-height: 16px;
  font-family: var(--var(--body-text));
  padding: 13.5px 10px;
  text-transform: capitalize;
}

.btn-like .offering-th-text {
  line-height: 1.25;
  white-space: nowrap;
}

img.lock-ic {
  width: 14px;
  height: 14px;
}

.offering-table-body .offering-tr {
  margin-bottom: 10px;
}

.offering-table-body .offering-tr:last-child {
  margin-bottom: 0;
}

.offering-table-head.tryed {
  background: none;
  justify-content: space-between;
  align-items: center;
}

.offering-cus-table .offering-table-head.tryed {
  padding: 0 0 10px;
}

.offering-table-head.tryed .title-main {
  font-family: var(--head-text);
  text-transform: uppercase;
  font-size: 18px;
}

.gradient-bg {
  background: var(--gradient);
}

.border-round-table {
  border-radius: 17px;
  overflow: hidden;
  border: 1px solid var(--table-border);
  background: var(--white);
}

.border-round-table>div {
  background: none;
  color: white;
}

.border-round-table>div td {
  /* color: white;
  background: var(--darkBlue);
  border-color: var(--pink); */
}

.border-round-table>div th {
  color: var(--white);
  font-family: var(--head-text);
  font-size: 12px;
  line-height: 27px;
  text-transform: uppercase;
}

.date-picker-custom input {
  /* color: white;  */
  padding: 8.6px 14px;
}

.date-picker-custom fieldset {
  border-color: white;
}

.date-picker-mui {
  border: 1px solid #d1d5db !important;
  border-radius: 6px !important;
}

.admin-header-logo-main {
  line-height: 1;
}

.offering-tr {
  margin-bottom: 10px;
  background: #d2d2d2;
  padding: 1px;
  border-radius: 6px;
}

.offering-tr .gradient-inner-box {
  background: var(--white);
}

.offering-td-box.quantity-box.th-quantity .lock-ic-main {
  border: 1px solid #d2d2d2;
}

.offering-tr .gradient-inner-box.lock-ic-main {
  border: 1px solid #d2d2d2;
}

.primary-btn button {
  background: var(--green);
  color: var(--black);
}

.form-wrape .crtl-wrape .inner-pad {
  padding: 15px;
}

.form-wrape .crtl-wrape .inner-pad2 {
  padding: 0;
}

.form-wrape .crtl-wrape .MuiGrid-container {
  margin-top: 0;
}

.form-wrape .crtl-wrape .MuiGrid-item {
  padding-top: 0;
}

textarea.input-fild {
  padding: 15px !important;
  font-family: var(--body-text);
}

textarea.input-fild.Mui-error {
  margin-bottom: 0 !important;
}

.title-header .title-header-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-header .title-header-flex .title-main {
  font-family: var(--head-text);
  text-transform: uppercase;
  font-size: 18px;
}

.MuiDateRangeCalendar-root>div:first-child {
  display: none;
}

.astrick-sing {
  color: red;
}

.datepicker-wrape div {
  width: auto;
  display: flex;
  justify-content: flex-end;
  padding-right: 0;
}
.datepicker-wrape .MuiStack-root{
  padding: 0px;
}

td .admin-icon {
  max-width: 24px;
}

.admin-login-main .input-box {
  margin-bottom: 15px;
}

.vdo-frame {
  position: absolute;
  opacity: 0.35;
  pointer-events: none;
  width: 120%;
  height: 100%;
}

.vdo-frame .presale-video {
  width: 100%;
  height: 100%;
}

.admin-login-inner .primary-btn-main {
  margin-top: 22px;
  max-width: 122px;
}

.admin-login-main .auth-logo-box {
  height: auto;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 15px;
}

.admin-login-main form {
  padding-top: 25px;
  border-top: 1px solid rgba(255, 255, 255, 0.45);
}

.admin-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-modal .admin-modal-inner.qr-scanner-modal-inner {
  max-width: 350px;
}

.admin-modal .admin-modal-title {
  color: black;
  font-size: 17px;
  line-height: 26px;
  font-weight: 500;
}

.admin-modal .admin-modal-inner {
  padding: 20px 15px;
  width: calc(100% - 30px);
  max-width: 500px;
  border: 1px solid #d3d3d361;
  border-radius: 10px;
  max-height: calc(100vh - 40px);
}

.admin-modal-hgt-scroll {
  max-height: calc(100vh - 190px);
  overflow: auto;
  padding: 10px 5px 10px 0px;
  margin-right: -5px;
}

.MuiBackdrop-root {
  background-color: rgb(0 0 0 / 70%);
}

.admin-modal-header .modal-close-btn {
  padding: 0px;
  min-width: 30px;
}

.admin-modal-header {
  border-bottom: 1px solid #d3d3d361;
  padding-bottom: 10px;
}

.admin-modal-close-icon {
  cursor: pointer;
}

.admin-modal-user-btn-flex {
  display: flex;
  justify-content: space-evenly;
  padding-top: 3px;
  gap: 10px;
}

.border-btn-main .border-btn,
.border-btn-main .border-btn:hover {
  border: 1px solid lightgray;
  border-radius: 25px;
  color: gray;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  padding: 8px 20px;
  text-transform: capitalize;
}

.modal-footer {
  padding: 15px 0px 0px;
  border-top: 1px solid #d3d3d361;
}

/* width */
.cus-scrollbar::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.cus-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.cus-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.cus-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.qr-main {
  max-width: max-content;
  width: 100%;
  margin: auto;
}

.qr-footer-main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px 0px;
}

.card-btn-flex {
  margin: 10px 0 0;
}

.no-data-in-list{
color: var(--black);
}
.date-search-wrapper{
display: flex;
align-items: center;
gap: 8px;
}
.date-search-wrapper .date-picker-custom{
min-width: 100% !important;
}



.pagination-design nav {
  display: flex;
}

.pagination-design nav ul li .MuiPaginationItem-previousNext {
  color: #003d90;
}

.pagination-design nav ul li button,
.pagination-design nav ul li button:hover {
  min-width: auto;
  height: auto;
  background: transparent;
  margin: 0;
  font-family: "inter-medium", sans-serif;
  color: #99a4b5;
}

.pagination-design nav ul li button.Mui-selected,
.pagination-design nav ul li button.Mui-selected:hover {
  background: transparent;
  color: #003d90;
}
.pagination-design .pagination-count{
  display: flex;
  justify-content: center;
}
.pagination-design {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}
.admin-search-box .admin-form-group{
position: relative;
}
.admin-search-grey-img{
  position: absolute;
    right: 11px;
    top: 35%;
}
.admin-search-box  input{
  padding: 14px 11px;
}
.pagination-count .input-design-div{
max-width: 70px;
}
.pagination-count .MuiSelect-select{
  padding: 4px;

}
.no-data-in-list {
  color: var(--black);
}

.phasetype-table-body .phasetype-tr {
  margin-bottom: 10px;
}

.phasetype-table-body .phasetype-tr:last-child {
  margin-bottom: 0;
}


.phasetype-cus-table .phasetype-table-body {
  padding: 15px 27px;
  border: 1px solid var(--pink);
  border-top: none;
  border-radius: 0 0 15px 15px;
}

.phasetype-cus-table .phasetype-table-head {
  background: var(--gradient);
  border-radius: 15px 15px 0 0;
  padding: 11px 42px 11px 42px;
  display: flex;
  /* justify-content: space-between; */
}

.phasetype-cus-table .th-blank {
  text-align: center;
  width: 8%;
}

.phasetype-cus-table .th-offe-type {
  text-align: center;
  width: 17%;
}

.phasetype-cus-table .th-quantity {
  text-align: center;
  width: 15%;
}

.phasetype-cus-table .th-offe-price {
  text-align: center;
  width: 15%;
}

.phasetype-cus-table .th-offe-raise {
  text-align: center;
  width: 15%;
}

.phasetype-cus-table .th-action {
  text-align: center;
  width: 15%;
}

.phasetype-cus-table .phasetype-th {
  color: var(--white);
  font-family: Poppins-Regular;
  font-size: 12px;
  line-height: 27px;
  min-width: 29px;
}

.phasetype-tr .phasetype-tr-main {
  padding: 8px 15px 8px 15px;
}

.phasetype-tr-main .lock-main {
  width: 30px;
  height: 30px;
}

.phasetype-list {
  width: 76%;
  display: flex;
  margin-left: 4%;
}

.phasetype-tr .phasetype-th-text {
  color: var(--white);
  font-family: Poppins-Medium;
  font-size: 12px;
  line-height: 27px;
}

.phasetype-list .phasetype-td-box {
  height: 30px;
  width: 100%;
}

.phasetype-list .inner-wrape {
  display: flex;
  width: 100%;
  gap: 88px;

  .th-quantity {
    width: 25%;
  }
}

.phasetype-table-head .phasetype-list .inner-wrape {
  padding: 0px 11px 0px 0;
}


.btn-like {
  background: linear-gradient(109deg, #FF387A 0%, #4845FF 100%) 0% 0%;
  border-radius: 15px;
  width: 100%;
  color: var(--white);
  font-size: 16px;
  line-height: 16px;
  font-family: var(--poppins-medium);
  padding: 13.5px 10px;
  text-transform: capitalize;
}

.btn-like .phasetype-th-text {
  line-height: 1.25;
  white-space: nowrap;
}

img.lock-ic {
  width: 14px;
  height: 14px;
}

.phasetype-table-body .phasetype-tr {
  margin-bottom: 10px;
}

.phasetype-table-body .phasetype-tr:last-child {
  margin-bottom: 0;
}

.custom-btn-group {
  display: flex !important;
  justify-content: flex-end !important;
  width: 100%;
}

.assign-btn,
.cancel-btn {
  padding: 7px 13px !important;
  margin-left: 6px !important;
  background-color: #4caf50;
  border: none !important;
  color: rgb(243, 246, 250) !important;
  text-align: center !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 13px !important;
  cursor: pointer !important;
  border-radius: 5px !important;
  transition: background-color 0.3s !important;
}

.cancel-btn {
  background-color: #887e7d !important;
  /* Red */
}

.assign-btn:hover,
.cancel-btn:hover {
  background-color: #4373b3 !important;
  /* Darker Green */
}

.assign-btn:focus,
.cancel-btn:focus {
  outline: none !important;
}

.ver-center {
  display: flex;
  align-items: center;
}

.lock-ic-main.border-none {
  border: none;
}

.input-fild .MuiInputBase-formControl {
  padding: 0;
}

.cus-cancel-btn {
  background-color: red !important;
  margin-right: 10px;
}

.custom-btn-group .primary-btn,
.custom-btn-group .primary-btn:hover {
  background: var(--blue);
  border-radius: 20px;
  width: 100%;
  color: var(--white);
  font-size: 12px;
  line-height: 14px;
  padding: 10px 21px;
  text-transform: uppercase;
  font-weight: 400;
}

.custom-btn-group .primary-btn-main {
  margin-right: 5px;
}

.cus-table {
  border-radius: 15px;
}

.cus-table-header {
  border-radius: 15px 15px 0 0;
}

.cus-table-header .cus-th,
.cus-table-body .cus-td {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cus-table-header .cus-th {
  color: var(--white);
  font-family: var(--head-text);
  font-size: 12px;
  line-height: 27px;
  min-width: 29px;
  text-transform: uppercase;

}

.cus-table .cus-table-header {
  padding: 11px 20px;
  background: var(--gradient);
}

.cus-table-body .cus-tr {
  padding: 10px;
  border: 1px solid var(--table-border);
  border-radius: 5px;
}

.cus-table-body {
  padding: 15px;
  border: 1px solid var(--table-border);
  border-top: none;
  border-radius: 0 0 15px 15px;
}

.cus-table.offering-list {
  display: block;
  width: 100%;
}

.cus-table.offering-list .offering-td-box {
  height: 44px;
  width: 80%;
}

.gradient-inner-box {
  background-color: var(--white);
}

.gradient-inner-box p,
.cus-select .MuiSelect-select {
  color: var(--black);
  font-family: var(--body-text);
  font-size: 12px;
  line-height: 27px;
  font-weight: 600;
}
.stat-box {
  display: flex;
  width: 100%;
  gap: 30px;
}

.stat-box {
  display: flex;
  width: 100%;
  gap: 30px;
}
.stat-box .stat {
  width: calc(100% / 3);
  display: flex;
  border: 1px solid #efefef;
  border-radius: 16px;
  padding: 30px;
  flex-wrap: wrap;
  position: relative;
  background: rgba(255, 255, 255, 1);
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.1 );
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 1);
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}
.stat-box .p-currency {
  background: var(--blue);
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stat.unlock .p-currency {
  background: var(--green);
}
.stat.available .p-currency {
  background: var(--black);
}
.stat-box .p-currency svg {
  height: 24px;
}
.stat-box h6 {
  font-size: 30px;
  font-weight: 600;
  font-family: var(--headfont);
  position: relative;
}

.stat-box .claim-lable {
  background: var(--blue);
  padding: 4px 15px 5px 15px;
  border-radius: 14px;
  display: inline-block;
  width: auto;
  position: absolute;
  right: 0;
  line-height: 1.25;
}
.stat-box .claim-lable span {
  color: var(--white);
  font-weight: 500;
  font-size: 13px;
}
.stat-box p {
  color: var(--black);
  opacity: 0.87;
  font-family: var(--bodyfont);
}
.stat-box .text-wrape {
  width: calc(100% - 60px);
}

.date-new{
  width: 100% !important;
  margin-left: 2% !important;
  margin-bottom: 5% !important;
}
.history-btn {
  cursor: pointer;
  background: var(--gradient);
  padding: 7px 12px;
  border-radius: 10px;
  font-size: 14px;
  color: white;
  margin-right: 10px;
  font-weight: 500;
}

.history-btn span {
  margin: 0 10px;
}

.history-custom-btn-group {
  display: flex;
  /* justify-content: space-between; */
  /* Adjust this value if you need different spacing */
}

.history-wraper {
  width: 100%;
  padding: 15px;
}

.history-table-body .history-tr {
  margin-bottom: 10px;
}

.history-table-body .history-tr:last-child {
  margin-bottom: 0;
}


.history-cus-table .history-table-body {
  padding: 15px 27px;
  border: 1px solid var(--pink);
  border-top: none;
  border-radius: 0 0 15px 15px;
}

.history-cus-table .history-table-head {
  background: var(--gradient);
  border-radius: 15px 15px 0 0;
  padding: 11px 42px 11px 42px;
  display: flex;
  /* justify-content: space-between; */
}

.history-cus-table .th-blank {
  text-align: center;
  width: 8%;
}

.history-cus-table .th-offe-type {
  text-align: center;
  width: 6%;
}

.history-cus-table .th-quantity {
  text-align: center;
  width: 15%;
}

.history-cus-table .th-offe-price {
  text-align: center;
  width: 15%;
}

.history-cus-table .th-offe-raise {
  text-align: center;
  width: 15%;
}

.history-cus-table .th-action {
  text-align: center;
  width: 15%;
}

.history-cus-table .history-th {
  color: var(--white);
  font-family: Poppins-Regular;
  font-size: 12px;
  line-height: 27px;
  min-width: 29px;
}

.history-tr .history-tr-main {
  padding: 8px 15px 8px 15px;
}

.history-tr-main .lock-main {
  width: 30px;
  height: 30px;
}

.history-list {
  width: 91%;
  display: flex;
  margin-left: 4%;
}

.history-tr .history-th-text {
  color: var(--white);
  font-family: Poppins-Medium;
  font-size: 12px;
  line-height: 27px;
}

.history-list .history-td-box {
  height: 30px;
  width: 100%;
}

.history-list .inner-wrape {
  display: flex;
  width: 100%;
  gap: 88px;

  .th-quantity {
    width: 25%;
  }
}

.history-table-head .history-list .inner-wrape {
  padding: 0px 11px 0px 0;
}


.btn-like {
  background: linear-gradient(109deg, #FF387A 0%, #4845FF 100%) 0% 0%;
  border-radius: 15px;
  width: 100%;
  color: var(--white);
  font-size: 16px;
  line-height: 16px;
  font-family: var(--poppins-medium);
  padding: 13.5px 10px;
  text-transform: capitalize;
}

.btn-like .history-th-text {
  line-height: 1.25;
  white-space: nowrap;
}

img.lock-ic {
  width: 14px;
  height: 14px;
}

.history-table-body .history-tr {
  margin-bottom: 10px;
}

.history-table-body .history-tr:last-child {
  margin-bottom: 0;
}

.table-btn {
  background: #2b5af3;
  background: var(--blue);
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
  height: 34px;
  padding: 9px;
  width: 34px;
}

.table-btn svg {
  height: 16px;
  width: 16px;
}

.modal-head {
  padding: 15px 20px 10px;
  position: relative;
  display: flex;
  width: 100%;
  font-family: var(--headfont);
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--blue);
  border-bottom: 1px solid #efefef;
}

.modal-body {
  display: flex;
  width: 100%;
  padding: 20px 20px 0px;
}

.account-data-ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-bottom: 20px;
  width: 100%;
}

.account-data-li {
  display: flex;
  gap: 10px;
}

.account-li-lable {
  font-size: 13px !important;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0px !important;
}

.account-li-data {
  font-size: 13px !important;
  color: #666666;
  font-weight: 400;
  margin-bottom: 0px !important;
}

.cus-modal-li {
  width: 100%;
  display: flex;
}

.modal-pad .modal-body .cus-modal-li p {
  font-size: 15px;
  color: #666666;
  font-weight: 400;
}

.modal-pad .cus-modal-li p.li-list-title {
  font-weight: 600;
  width: 160px;
  color: #000000;
}

.modal-footer {
  padding: 10px 15px 10px;
  display: flex;
  justify-content: flex-start;
}

.modal-footer .modal-btn-sec button {
  padding: 10px 25px;
  background: var(--blue);
  color: var(--white);
  font-family: var(--headfont);
  text-transform: uppercase;
  border: 15px;
}

.sticky-glass-bgs .table-wrape th,
.sticky-glass-bgs .table-wrape td {
  text-align: center;
}

.sticky-glass-bgs .table-wrape th:first-child,
.sticky-glass-bgs .table-wrape td:first-child {
  max-width: 30px;
}

.view-flex {
  display: grid;
  gap: 20px;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
}

.view-flex .view-content .view-content-btn {
  background-color: transparent;
  padding: 4px 7px;
  font-size: 14px;
  border-radius: 5px;
  text-transform: capitalize;
  border: 1px solid #e79654;
  color: #e79654;
}

.view-wrapper {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 10px;
  margin: 0px 10px 10px;
  background: #000;
  padding: 10px;
}

.view-content {
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
}

.view-alert-box .MuiAlert-message {
  padding: 0px !important;
  width: 100%;
}

.modal-wrape .modal-pad {
  border: 0;
  border-radius: 24px;
  max-width: 450px;
  width: 90%;
}

.modal-wrape .modal-pad h2 {
  text-transform: uppercase;
  font-size: 30px;
  margin-bottom: 30px;
  font-family: var(--headfont);
  font-weight: 600;
}

.modal-wrape .modal-pad h2 span.frame {
  display: inline-block;
  position: relative;
  font-size: 30px;
  line-height: 40px;
  padding: 0px 10px 3px;
}

.modal-wrape .modal-pad h2 span.frame:after {
  background: url(../images/svg/modal-frame.svg) no-repeat 0 0;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
}

.modal-wrape .modal-pad p {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 500;
}

.modal-wrape .modal-pad .input-wrape {
  margin-bottom: 15px;
}

/* .modal-wrape .modal-pad a.modal-close {
  width: 32px;
  height: 32px;
  padding: 6px 11px 0;
  position: absolute;
  right: 10px;
  top: 10px;
  background: var(--green);
  border-radius: 50%;
} */
.modal-wrape .modal-pad a.modal-close {
  background: #38ff8e;
  background: var(--green);
  border-radius: 50%;
  height: 32px;
  padding: 0px 11px 0;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 32px;
}

svg {
  width: 100%;
  height: auto;
}

.copy-svg {
  width: 34%;
  height: auto;
}

.claim-right-main {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 11px;
  color: #000;
}

/* Pagination Button Styles */
.pagination-button {
  padding: 8px 12px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.pagination-button:hover {
  background-color: #f0f0f0;
}

.pagination-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.pagination-text {
  margin: 0 10px;
  color: #000;
}

.copy-flex {
  display: flex;
  gap: 5px;
  align-items: center;
}

.copy-flex button {
  min-width: 30px;
  padding: 0;
}

.copy-icon {
  height: 16px;
  width: 16px;
}

.copy-btn {
  margin-right: -20% !important;
  margin-left: -11% !important;
}